import { differenceInHours } from 'date-fns'

import type { DistantApiResponse } from '~/types/distantApi'
import type { ExternalTrack } from '~/types/ExternalTrack'
import type { Locale } from '~/types/locale'
import type { SpotifyPlaylistDetails } from '~/types/spotify-core-api'

export type SpotimoodDetailCache = {
  details: SpotifyPlaylistDetails
  id: string
  lastUpdated: number
}

/**
 * A simple representation of an IDB Object store.
 */
type StripedObjectStoreDefinition = {
  name: string
  keyPath: string | string[]
}

/**
 * A simple representation of an IDB Object used for type checking only.
 */
type ObjectStoreDefinition = {
  store: Record<string, any>
} & StripedObjectStoreDefinition

/**
 * Quickhand to build a Strongly type `ObjectStoreDefinition` from arguments.
 *
 * @param {string} NAME - The target databae name.
 * @param STORE - The target Schema structure.
 * @returns {ObjectStoreDefinition} - A strongly typed `ObjectStoreDefinition`.
 */
type BuildObjectStore<
  NAME extends string,
  STORE extends Record<string, any>,
  KEY_PATH extends keyof STORE | (keyof STORE)[],
> = { name: NAME; store: STORE; keyPath: KEY_PATH }

/**
 * A simple representation of an IDB used for type checking only.
 */
type DataBaseEntry = {
  name: string
  storeSchemas: ObjectStoreDefinition[]
  version: number
}

/**
 * A simple representation of an IDB used for runtime type guaranties.
 */
type StripedDataBaseEntry = {
  name: string
  storeSchemas: StripedObjectStoreDefinition[]
  version: number
}

/**
 * Quickhand to build a Strongly type `ObjectStoreDefinition` from arguments.
 */
type BuildDatabase<
  NAME extends string,
  VERSION extends number,
  STORES extends ObjectStoreDefinition[],
> = { name: NAME; version: VERSION; storeSchemas: STORES }

/**
 * Returns all available schema names from a set of `DataBaseEntry`.
 *
 * @param {DataBaseEntry} DB_ENTRY - The taget `DatabaseEntry`.
 * @returns {string | never} A union of all names or never if the entry is not given or no schemas could be found.
 */
type GetSchemaNames<DB_ENTRY extends DataBaseEntry> =
  DB_ENTRY['storeSchemas'][number]['name']

/**
 * The main source of all further computed types for IDBX's.
 * This defines every data base in the system, their corresponding version and schemas.
 */
type IndexedDbLibrary = [
  BuildDatabase<
    'DistantApi',
    2,
    [
      BuildObjectStore<
        'apiCache',
        DistantApiResponse & { callURL: string },
        'callURL'
      >,
      BuildObjectStore<
        'soundiizSuggestLinks',
        { results: ExternalTrack[]; link: string; lastUpdated: number },
        'link'
      >,
    ]
  >,
  BuildDatabase<
    'Spotimood',
    1,
    [BuildObjectStore<'apiCache', SpotimoodDetailCache, 'id'>]
  >,
  BuildDatabase<
    'AccountSwap',
    2,
    [BuildObjectStore<'secret', { value: string; userId: number }, 'userId'>]
  >,
  BuildDatabase<
    'ComputeCache',
    1,
    [
      BuildObjectStore<
        'influencerCardPillContentCache',
        {
          value: string[]
          influencerIdAndFilterIds: `${number}-${string}-${Locale}`
          lastUpdated: number
        },
        'influencerIdAndFilterIds'
      >,
    ]
  >,
]

/**
 * Quickhand that transforms the main `IndexedDbLibrary` to a Union type for further manipulation down the line.
 */
type IndexedDbLibraryUnion = IndexedDbLibrary[number]

/**
 * Quickhand of all the available `DataBaseEntry` names in the main Library (`IndexedDbLibrary`).
 */
type AvailableDataBasesName = IndexedDbLibraryUnion['name']

/**
 * Creates a Union type of all available versions for a database name.
 *
 * @param {string} NAME - The targeted database names.
 * @returns { number | never } - Returns a union of numbers corrsponding to the available version of this entry or never if none where found or if no entry could be found.
 */
type AvailableDataBasesVersionsFromName<NAME extends AvailableDataBasesName> =
  IndexedDbLibraryUnion extends infer CURRENT
    ? CURRENT extends DataBaseEntry
      ? CURRENT['name'] extends NAME
        ? CURRENT['version']
        : never
      : never
    : never

/**
 * .
 * Get the corresponding `DatabaseEntry` from a given name and version copmbination.
 * @param {string} NAME - The searched for name of the database.
 * @param {string} VERSION - The searched for version of the database.
 *
 * @returns { DatabaseEntry | never } Returns the matching entry or never.
 */
type GetDataBaseEntryFromNameAndVersion<
  NAME extends AvailableDataBasesName,
  VERSION extends AvailableDataBasesVersionsFromName<NAME>,
> = IndexedDbLibraryUnion extends infer CURRENT
  ? CURRENT extends DataBaseEntry
    ? CURRENT['name'] extends NAME
      ? CURRENT['version'] extends VERSION
        ? CURRENT
        : never
      : never
    : never
  : never

/**
 * Extract the schema struture type from a schema name in a `DataBaseEntry`.
 *
 * @param {DataBaseEntry} DB_ENTRY - The target entry.
 * @param {string} S_NAME - The target schema name.
 * @returns {Record<string, any> | never} - If no schema with the supplied name was found this returns never, in the case it does fiund one the schema will be returned.
 */
type GetSchemaStructureFromNameInDbEntry<
  DB_ENTRY extends DataBaseEntry,
  S_NAME extends GetSchemaNames<DB_ENTRY>,
> = DB_ENTRY['storeSchemas'][number] extends infer CURRENT
  ? CURRENT extends DataBaseEntry['storeSchemas'][number]
    ? CURRENT['name'] extends S_NAME
      ? CURRENT['store']
      : never
    : never
  : never

/**
 * Utility type to build the end object returned by the `getDbTransaction()` utility function.
 * This exposes the main data manimulation API in `objectStore`.
 *
 */
type BuildTransactionNodeFromDbEntry<DB extends DataBaseEntry> = {
  objectStore: <
    S_NAME extends GetSchemaNames<DB>,
    MODE extends IDBTransactionMode,
  >(
    storeName: S_NAME,
    IDBTransactionMode: MODE,
  ) => {
    /**
     * Adds or updates a record in store with the given value and key.
     *
     * If the store uses in-line keys and key is specified a "DataError" DOMException will be thrown.
     *
     * If put() is used, any existing record with the key will be replaced. If add() is used, and if a record with the key already exists the request will fail, with request's error set to a "ConstraintError" DOMException.
     *
     * If successful, request's result will be the record's key.
     */
    put: MODE extends 'readonly'
      ? undefined
      : (
          value: GetSchemaStructureFromNameInDbEntry<DB, S_NAME>,
        ) => Promise<void>
    /**
     * Adds or updates a record in store with the given value and key.
     *
     * If the store uses in-line keys and key is specified a "DataError" DOMException will be thrown.
     *
     * If put() is used, any existing record with the key will be replaced. If add() is used, and if a record with the key already exists the request will fail, with request's error set to a "ConstraintError" DOMException.
     *
     * If successful, request's result will be the record's key.
     */
    add: MODE extends 'readonly'
      ? undefined
      : (
          value: GetSchemaStructureFromNameInDbEntry<DB, S_NAME>,
        ) => Promise<void>
    /**
     * Retrieves the value of the first record matching the given key or key range in query.
     *
     * If successful, request's result will be the value, or undefined if there was no matching record.
     */
    get: (
      query: number | string,
    ) => Promise<GetSchemaStructureFromNameInDbEntry<DB, S_NAME> | undefined>
    /**
     * Deletes records in store with the given key or in the given key range in query.
     *
     * If successful, request's result will be undefined.
     */
    delete: MODE extends 'readonly'
      ? undefined
      : (query: number | string) => Promise<void>
  }
}

async function promisifyIndexedDbRequest(request: IDBRequest) {
  return new Promise((resolve, reject) => {
    request.onsuccess = () => {
      resolve(request.result)
    }
    request.onerror = reject
  })
}

type BuildStripedObjectStoreEntryFromFullSet<
  ENTRIES extends ObjectStoreDefinition[],
  RESULTS extends StripedObjectStoreDefinition[] = [],
> = ENTRIES extends [infer CURRENT, ...infer END]
  ? CURRENT extends ObjectStoreDefinition
    ? END extends ObjectStoreDefinition[]
      ? BuildStripedObjectStoreEntryFromFullSet<
          END,
          [...RESULTS, { name: CURRENT['name']; keyPath: CURRENT['keyPath'] }]
        >
      : [...RESULTS, { name: CURRENT['name']; keyPath: CURRENT['keyPath'] }]
    : END extends ObjectStoreDefinition[]
      ? BuildStripedObjectStoreEntryFromFullSet<END, [...RESULTS]>
      : RESULTS
  : RESULTS

type BuildStripedDbEntryFromFull<ENTRY extends DataBaseEntry> = {
  name: ENTRY['name']
  version: ENTRY['version']
  storeSchemas: BuildStripedObjectStoreEntryFromFullSet<ENTRY['storeSchemas']>
}

type RemoveSchemaDefinition<
  LIB extends DataBaseEntry[] = IndexedDbLibrary,
  RESULTS extends StripedDataBaseEntry[] = [],
> = LIB extends [infer CURRENT, ...infer END]
  ? CURRENT extends DataBaseEntry
    ? END extends DataBaseEntry[]
      ? RemoveSchemaDefinition<
          END,
          [...RESULTS, BuildStripedDbEntryFromFull<CURRENT>]
        >
      : [...RESULTS, BuildStripedDbEntryFromFull<CURRENT>]
    : never
  : RESULTS

/**
 * The runtime equivalent of the main Library type.
 */
const RUNTIME_DEFINITION: RemoveSchemaDefinition = [
  {
    name: 'DistantApi',
    version: 2,
    storeSchemas: [
      {
        keyPath: 'callURL',
        name: 'apiCache',
      },
      {
        keyPath: 'link',
        name: 'soundiizSuggestLinks',
      },
    ],
  },
  {
    name: 'Spotimood',
    storeSchemas: [
      {
        keyPath: 'id',
        name: 'apiCache',
      },
    ],
    version: 1,
  },
  {
    name: 'AccountSwap',
    version: 2,
    storeSchemas: [
      {
        keyPath: 'userId',
        name: 'secret',
      },
    ],
  },
  {
    name: 'ComputeCache',
    version: 1,
    storeSchemas: [
      {
        keyPath: 'influencerIdAndFilterIds',
        name: 'influencerCardPillContentCache',
      },
    ],
  },
]

function generateSchemasByNameAndVersion<
  NAME extends AvailableDataBasesName,
  VERSION extends AvailableDataBasesVersionsFromName<NAME>,
>(dataBaseName: NAME, dataBaseVersion: VERSION, db: IDBDatabase) {
  const entry = RUNTIME_DEFINITION.find(
    ({ name, version }) => name === dataBaseName && version === dataBaseVersion,
  )

  if (!entry)
    throw new Error(
      `No runtime entry found for ${dataBaseName} at version ${dataBaseVersion}`,
    )

  for (const schema of entry.storeSchemas) {
    try {
      db.deleteObjectStore(schema.name)
    } catch (_) {}
    db.createObjectStore(schema.name, { keyPath: schema.keyPath })
  }
}

/**
 * Attempts to open a connection to the named database with the current version, or 1 if it does not already exist. If the request is successful a `transaction` will be name available.
 *
 * @param {string} dbName - The name of the target IDB.
 * @param {number} version - The version of the target IDB.
 * @returns {Promise} - The target transaction object with the available `objectStore` API.
 *
 * @example
 * const transaction = await getDbTransaction('test', 1)
 * const store = transaction.objectStore('test', 'readwrite')
 * // Add stuff to the db
 * await store.put({ foo: 'hjej', id: 0 })
 * // Add get stuff from the db
 * const result = await store.get(0)
 * //...
 */
export async function getDbTransaction<
  NAME extends AvailableDataBasesName,
  VERSION extends AvailableDataBasesVersionsFromName<NAME>,
  DB_ENTRY extends DataBaseEntry = GetDataBaseEntryFromNameAndVersion<
    NAME,
    VERSION
  >,
>(
  dbName: NAME,
  version: VERSION,
): Promise<BuildTransactionNodeFromDbEntry<DB_ENTRY>> {
  if (!process.client && !process.env.isJestTests)
    throw new Error('[IndexedDb] This utility is client only !')

  return new Promise((resolve, reject) => {
    const dbOpen = indexedDB.open(dbName, version)

    // Create schema in runtime
    dbOpen.onupgradeneeded = function () {
      generateSchemasByNameAndVersion(dbName, version, dbOpen.result)
    }
    dbOpen.onerror = reject
    dbOpen.onsuccess = function () {
      if (!dbOpen.result.transaction)
        return reject(new Error('TransactionMissing'))

      return resolve({
        // @ts-expect-error Trust me fam frfr
        objectStore: (name, mode) => {
          const tx = dbOpen.result.transaction(name, mode)
          const store = tx.objectStore(name)

          return {
            add:
              mode === 'readonly'
                ? undefined
                : (value) => {
                    return promisifyIndexedDbRequest(store.add(value))
                  },
            put:
              mode === 'readonly'
                ? undefined
                : (value) => {
                    return promisifyIndexedDbRequest(store.put(value))
                  },
            delete:
              mode === 'readonly'
                ? undefined
                : (query) => {
                    return promisifyIndexedDbRequest(store.delete(query))
                  },
            get: (query) => {
              return promisifyIndexedDbRequest(store.get(query))
            },
          }
        },
      })
    }
  })
}

export function timestampDidNotExeedTtl(
  lastUpdated: number,
  ttl = 2,
  comparator: typeof differenceInHours = differenceInHours,
) {
  const currentTimeStamp = Date.now()

  return Math.abs(comparator(currentTimeStamp, lastUpdated)) < ttl
}
